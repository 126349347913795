import React from "react";

interface FooterRightProps {
  windowSize: number;
}

const FooterRight: React.FC<FooterRightProps> = ({ windowSize }) => {
  return (
    <>
      <div className="grid w-10/12 md:w-6/12 h-full md:h-60 justify-evenly mt-3 grid-cols-2 md:grid-cols-3">
        <div className="flex flex-col w-full justify-evenly ">
          <a href="#" className="w-28 font-semibold">
            Menu
          </a>
          <a
            href="https://docs.v-empire.io/vempire-ddao/"
            target="_blank"
            className="w-28 "
            rel="noreferrer"
          >
            Docs
          </a>
          <a
            href="https://v-empire.io/games/"
            target="_blank"
            className="w-28 "
            rel="noreferrer"
          >
            Games
          </a>
          <a
            href="https://v-empire.io/contact/"
            target="_blank"
            className="w-28"
            rel="noreferrer"
          >
            Contact
          </a>
        </div>
        <div className="flex flex-col w-full justify-evenly">
          <a href="#about" className="w-28 font-semibold">
            About
          </a>
          <a
            href="https://v-empire.io/wp-content/uploads/2021/12/vEMPIRE-1.2.pdf"
            target="_blank"
            className="w-28"
            rel="noreferrer"
          >
            Whitepaper
          </a>
          <a
            href="https://v-empire.io/wp-content/uploads/2021/12/vEmpire-Lightpaper.pdf"
            target="_blank"
            className="w-28"
            rel="noreferrer"
          >
            Litepaper
          </a>

          <a
            href="https://v-empire.io/team/"
            target="_blank"
            className="w-28"
            rel="noreferrer"
          >
            Team
          </a>
        </div>
        <div className="flex flex-col w-full justify-evenly">
          <a href="#" className="w-28 font-semibold">
            Legal
          </a>
          <a
            href="https://v-empire.io/terms-of-use/"
            target="_blank"
            className="w-28"
            rel="noreferrer"
          >
            Terms of Use
          </a>
          <a
            href="https://v-empire.io/privacy-policy/"
            target="_blank"
            className="w-28"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
          <a
            href="https://v-empire.io/cookie-policy/"
            target="_blank"
            className="w-28"
            rel="noreferrer"
          >
            Cookie Policy
          </a>
        </div>
      </div>
      {windowSize < 769 ? (
        <div className=" w-11/12 mb-3 mt-6 flex flex-col items-center sm:items-start sm:pl-7">
          <div className="flex justify-between w-9/12 sm:w-56 ">
            <a
              href="https://twitter.com/vEmpireDDAO"
              target="_blank"
              rel="noreferrer"
            >
              <img className="w-7" src="/images/twitter.png" alt="Not Found" />
            </a>
            <a
              href="https://discord.com/invite/Wk3aF3PNKM"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="w-7"
                src="/images/blackDiscord.png"
                alt="Not Found"
              />
            </a>
            <a
              href="https://t.me/vEmpireannouncements"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="w-7"
                src="/images/telegramm.png"
                alt="Not Found"
              />
            </a>

            <a
              href="https://www.youtube.com/c/vEmpireDDAO"
              target="_blank"
              rel="noreferrer"
            >
              <img className="w-7" src="/images/youtube.png" alt="Not Found" />
            </a>

            <a
              href="https://v-empire-digital.medium.com/"
              target="_blank"
              rel="noreferrer"
            >
              <img className="w-7" src="/images/medium.png" alt="Not Found" />
            </a>
          </div>
          <p className=" mt-3">Copyright &copy; 2022 vEmpire</p>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default FooterRight;
