import { nftAddress, plebNftAddress } from "./Addresses";
import nftABI from "./ABI/nft.json";
import plebABI from "./ABI/plebNFT.json";
import { getProof } from "../Phases/whiteListedAddresses";
import BigNumber from "bignumber.js";

var Web3 = require("web3");
const web3 = new Web3(Web3.givenProvider || "ws://localhost:8545");

export const getAccount = async () => {
  try {
    const account = await window.ethereum.request({ method: "eth_accounts" });
    return account;
  } catch (error) {
    return "";
  }
};

export const hexToNumber = (hex) => web3.utils.hexToNumber(hex);
export const getChainId = async () => {
  try {
    const chainId = await web3?.eth?.getChainId();
    localStorage.setItem("chainId", hexToNumber(chainId));
    const checkNetwork =
      hexToNumber(chainId) === 1 || hexToNumber(chainId) === 56;
    localStorage.setItem("correctNetwork", `${checkNetwork}`);
    return hexToNumber(chainId);
  } catch (e) {
    return "";
  }
};

export const chainChanged = async () => {
  window.ethereum.on("chainChanged", (chainId) => {
    localStorage.setItem("chainId", hexToNumber(chainId));
    window.location.reload();
  });
};

export const getBalance = async (account) => {
  const balance = await web3.eth.getBalance(account);
  return (balance / Math.pow(10, 18)).toFixed(4);
};

//////// Nft --------------------------------------------

export const mintPhaseAndDuration = async () => {
  const nftContract = new web3.eth.Contract(nftABI, nftAddress);
  try {
    const phaseOneSupply = await nftContract.methods.maxSupplyPhaseOne().call();

    const phaseThreeSupply = await nftContract.methods.maxSupply().call();

    const totalMinted = 6 + parseInt(await nftContract.methods.bought().call());

    const phaseOneSpan = await nftContract.methods.phaseOneDuration().call();
    const phaseTwoSpan = await nftContract.methods.phaseTwoDuration().call();

    const phaseOneSTime = await nftContract.methods.phaseOneStartTime().call();
    const phaseTwoSTime = await nftContract.methods.phaseTwoStartTime().call();
    const phaseThreeSTime = await nftContract.methods
      .phaseThreeStartTime()
      .call();

    const phaseOneTMinted = 6 + parseInt(await phaseMint(1));
    const phaseTwoTMinted = await phaseMint(2);
    const phaseThreeTMinted = await phaseMint(3);

    const plebContract = new web3.eth.Contract(plebABI, plebNftAddress);
    const maxSupplyPlebToken = await plebContract.methods.MAX_SUPPLY().call();
    // const maxSupplyPlebToken = "4500";

    // console.log("phasesDAta", {
    //   phaseOneSupply,
    //   phaseThreeSupply,
    //   totalMinted,
    //   phaseOneSpan,
    //   phaseTwoSpan,
    //   phaseOneSTime,
    //   phaseTwoSTime,
    //   phaseThreeSTime,
    //   phaseOneTMinted,
    //   phaseTwoTMinted,
    //   phaseThreeTMinted,
    // });

    return {
      phaseOneSupply,
      phaseThreeSupply,
      totalMinted,
      phaseOneSpan,
      phaseTwoSpan,
      phaseOneSTime,
      phaseTwoSTime,
      phaseThreeSTime,
      phaseOneTMinted,
      phaseTwoTMinted,
      phaseThreeTMinted,
      maxSupplyPlebToken,
    };
  } catch (error) {
    // console.log(error);
    return 0.0;
  }
};

// // Function to call allowance function of cultToken

export const Buy = async (amount, phase) => {
  const account = await getAccount();
  if (!account.length) {
    return 0;
  }
  const nftContract = new web3.eth.Contract(nftABI, nftAddress);
  const PhaseOnePrice = await nftContract.methods.phaseOnePrice().call();
  const PhaseTwoPrice = await nftContract.methods.phaseTwoPrice().call();
  const PhaseThreePrice = await nftContract.methods.phaseThreePrice().call();

  // console.log("phase3", PhaseThreePrice, PhaseOnePrice, PhaseTwoPrice);

  try {
    let proof = await getProof(phase);
    if (phase === 1) {
      // console.log("phase1", phase, amount);
      const mint = await nftContract.methods.buy(amount, phase, proof).send({
        from: account[0],
        value: PhaseOnePrice * new BigNumber(amount),
      });
    } else if (phase === 2) {
      const mint = await nftContract.methods.buy(amount, phase, proof).send({
        from: account[0],
        value: PhaseTwoPrice * new BigNumber(amount),
      });
    } else {
      proof = [];
      const mint = await nftContract.methods.buy(amount, phase, proof).send({
        from: account[0],
        value: PhaseThreePrice * new BigNumber(amount),
      });
    }
    return 1;
  } catch (error) {
    return 0;
  }
};

export const Redeem = async () => {
  const account = await getAccount();
  if (!account.length) {
    return 0;
  }
  const nftContract = new web3.eth.Contract(nftABI, nftAddress);
  try {
    const claimed = await nftContract.methods
      .redeem()
      .send({ from: account[0] });
    return claimed;
  } catch (e) {
    // console.log(e);
    return 0;
  }
};

export const totalClaimableNft = async () => {
  const account = await getAccount();
  if (!account.length) {
    return 0;
  }
  const nftContract = new web3.eth.Contract(nftABI, nftAddress);
  const totalBuyedNFT = await nftContract.methods
    .addressToTicketsPermissioned(account[0])
    .call();
  const totalClaimedNFT = await nftContract.methods
    .addressToMints(account[0])
    .call();
  // console.log("total", totalBuyedNFT, totalClaimedNFT);
  return totalBuyedNFT - totalClaimedNFT;
};

export const nftLimit = async () => {
  const nftContract = new web3.eth.Contract(nftABI, nftAddress);
  const limit = await nftContract.methods.limit().call();

  return limit;
};

export const totalNftBuyed = async () => {
  const account = await getAccount();
  if (!account.length) {
    return 0;
  }
  const nftContract = new web3.eth.Contract(nftABI, nftAddress);
  const totalBuyedNFT = await nftContract.methods
    .addressToTicketsPermissioned(account[0])
    .call();
  return parseInt(totalBuyedNFT);
};

export const phaseMint = async (phase) => {
  const nftContract = new web3.eth.Contract(nftABI, nftAddress);
  const mint = await nftContract.methods.phaseMints(phase).call();

  return mint;
};
