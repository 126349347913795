import { phaseOneAddresses, phaseTwoAddresses } from "../Common/Addresses";
import keccak256 from "keccak256";
import { getAccount } from "../Common/nftFunctions";
const { MerkleTree } = require("merkletreejs");

export const getProof = async (phase) => {
  let leafnodes;
  if (phase === 1) {
    leafnodes = phaseOneAddresses.map((addr) => keccak256(addr));
  } else {
    leafnodes = phaseTwoAddresses.map((addr) => keccak256(addr));
  }

  const tree = new MerkleTree(leafnodes, keccak256, { sort: true });
  const account = await getAccount();
  if (!account.length) {
    return 0;
  }
  const root = tree.getHexRoot();
  let PhaseOneProof = keccak256(account[0]);
  const proof = tree.getHexProof(PhaseOneProof);
  // console.log("Root ", root);
  // console.log("whitelisted", tree.verify(proof, PhaseOneProof, root));

  return proof;
};

export const isWhitelisted = async (phase) => {
  let leafnodes;
  if (phase === 1) {
    leafnodes = phaseOneAddresses.map((addr) => keccak256(addr));
  } else if (phase === 2) {
    leafnodes = phaseTwoAddresses.map((addr) => keccak256(addr));
  } else {
    return true;
  }

  const tree = new MerkleTree(leafnodes, keccak256, { sort: true });
  const account = await getAccount();
  if (!account.length) {
    return 0;
  }
  const root = tree.getHexRoot();
  let PhaseOneProof = keccak256(account[0]);
  const proof = tree.getHexProof(PhaseOneProof);

  // console.log("Root ", root);
  // console.log("whitelisted", tree.verify(proof, PhaseOneProof, root));

  return tree.verify(proof, PhaseOneProof, root);
};
