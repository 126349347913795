import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectNFT } from "../features/counter/nftSlice";
import { toast } from "react-toastify";
var Web3 = require("web3");
const web3 = new Web3(Web3.givenProvider || "ws://localhost:8545");

function Header() {
  const { isMetamask, networkId } = useSelector(selectNFT);
  const [toggleMenu, setToggleMenu] = useState(false);

  const [accont, setAccont] = useState("");

  console.log(toggleMenu);

  const notify = (Message) => toast(Message);
  useEffect(() => {
    if (window && window.ethereum) {
      web3.eth
        .requestAccounts()
        .then((a) => setAccont(a))
        .catch((err) => {
          // Some unexpected error.
          // For backwards compatibility reasons, if no accounts are available,
          // eth_accounts will return an empty array.
          console.error(err);
        });
    }
  }, []);
  if (window && window.ethereum) {
    window.ethereum.on("accountsChanged", (a) => setAccont(a));
  }
  return (
    <>
      {toggleMenu ? (
        <>
          <div className="visible sm:invisible  w-8/12   h-screen fixed background z-50">
            <div className="h-96 flex flex-col items-left justify-evenly">
              <img className="w-44 pl-5" src="/images/vEmpireLogo.png" />
              <div className="flex flex-col w-full h-56 text-left font-bold text-lg text-white pt-5 pl-8">
                <a href="#about" className="font-semibold py-2">
                  About
                </a>
                <a href="#trait" className="font-semibold py-2">
                  Traits
                </a>
                <a href="#utility" className="font-semibold py-2">
                  Utility
                </a>
              </div>
            </div>
          </div>
          <div
            className="fixed w-full h-screen"
            onClick={() => setToggleMenu(!toggleMenu)}
          ></div>
        </>
      ) : (
        ""
      )}
      <div className="flex w-11/12 relative m-auto mt-6 justify-between content-center items-center text-white font-medium ">
        <div className="invisible sm:visible flex w-0 sm:w-8/12 justify-between items-center">
          <img className="w-44" src="/images/vEmpireLogo.png" />
          <div className="flex w-96 justify-evenly">
            <a href="#about" className="font-semibold">
              About
            </a>
            <a href="#trait" className="font-semibold">
              Traits
            </a>
            <a href="#utility" className="font-semibold">
              Utility
            </a>
          </div>
        </div>
        <button
          className="visible sm:invisible absolute top-1 left-1"
          onClick={() => setToggleMenu(!toggleMenu)}
        >
          <img src="images/menu.png" alt="image not found" />
        </button>

        <div>
          <button
            style={{ wordSpacing: "2px", fontSize: "15px" }}
            className="uppercase border-2 rounded-full px-3 sm:px-8 h-10 border-white font-semibold text-xs leading-9"
            onClick={() =>
              !isMetamask
                ? notify(
                    "Metamask not found, please install metamask to continue"
                  )
                : web3.eth
                    .requestAccounts()
                    .then((a) => setAccont(a))
                    .catch((err) => {
                      if (err.code === 4001) {
                        // EIP-1193 userRejectedRequest error
                        // If this happens, the user rejected the connection request.
                        console.log("Please connect to MetaMask.");
                      } else {
                        console.error(err);
                      }
                    })
            }
          >
            {accont.length
              ? accont.toString().slice(0, 10) + "..."
              : "CONNECT WALLET"}
          </button>
        </div>
      </div>
    </>
  );
}

export default Header;
