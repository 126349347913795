import React from "react";

function Utility() {
  return (
    <div id="utility" className="h-full md:h-screen pt-20">
      <div
        style={{ backgroundColor: "#211436" }}
        className="w-full h-8/12 m-auto flex flex-col items-center justify-evenly text-white text-center"
      >
        <p className="mt-8 text-3xl sm:text-5xl font-semibold">Utility</p>
        <div className="md:flex-row flex-col-reverse w-8/12 h-full flex items-center justify-between mt-12  text-center">
          <img
            className="w-80 md:h-96 h-72"
            src="images/phone.png"
            alt="image not found"
          />
          <div className="flex flex-col w-6/12 h-96 justify-evenly items-center">
            <div className="flex justify-between w-12/12">
              <div
                style={{ border: "2px solid #B147DB" }}
                className="w-16 h-16 mr-8"
              >
                <img
                  className="m-auto mt-2"
                  src="images/stake.png"
                  alt="image not found"
                />
              </div>
              <div className="text-left">
                <p className="font-bold">Stake Your Pleb NFTs</p>
                <p className="w-52 sm:w-80">
                  Earn 25% of the total game fees from “vEmpire: The Beginning”
                  paid in $VEMP.
                </p>
              </div>
            </div>
            <div className="flex justify-between w-12/12 ">
              <div
                style={{ border: "2px solid #B147DB" }}
                className="w-16 h-16 mr-8"
              >
                <img
                  className="m-auto mt-3"
                  src="images/Layer.png"
                  alt="image not found"
                />
              </div>
              <div className="text-left">
                <p className="font-bold">Compound Your Rewards</p>
                <p className="w-52 sm:w-80">
                  Restake your $VEMP into our DDAO to compound your rewards and
                  gain fractional exposure to the Metaverse.
                </p>
              </div>
            </div>
            <a
              href="https://app.v-empire.io/plebs"
              target="_blank"
              className="button mt-3 w-40 "
              rel="noreferrer"
            >
              Stake Now
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Utility;
