import React, { useEffect, useState } from "react";
import {
  Buy,
  getAccount,
  mintPhaseAndDuration,
  nftLimit,
  phaseMint,
  Redeem,
  totalClaimableNft,
  totalNftBuyed,
} from "../../Common/nftFunctions";
import { toast } from "react-toastify";
import { isWhitelisted } from "../../Phases/whiteListedAddresses";
import { useSelector } from "react-redux";
import { selectNFT } from "../../features/counter/nftSlice";
import Countdown from "react-countdown";

function CouncilPartA() {
  const [mintValue, setMintValue] = useState(0);
  const [mintPhaseDuration, setMintPhaseDuration] = useState({});
  const [currentTime, setCurrentTime] = useState("");
  const [currentPhase, setPhase] = useState("");
  const [currentPhaseNumber, setCurrentPhaseNumber] = useState(1);
  const [totalClaimableNFT, setTotalClaimableNFT] = useState(0);
  const [nftLIMIT, setNftLimit] = useState(10);
  const [totalNFTbuyed, setTotalNFTbuyed] = useState(0);
  const [timer, setTimer] = useState("");
  const [disable, setDisable] = useState(false);
  const [mintDisable, setMintDisable] = useState(false);

  const { networkId } = useSelector(selectNFT);
  const nId = 56;

  // 56

  // console.log("nftLimit", nftLIMIT);

  const d = async () => {
    const tClaimedNft = await totalClaimableNft();
    setTotalClaimableNFT(tClaimedNft);
    const nftL = await nftLimit();
    setNftLimit(nftL);
    const tNftB = await totalNftBuyed();
    setTotalNFTbuyed(tNftB);
    const mintData = await mintPhaseAndDuration();
    setMintPhaseDuration(mintData);
    const currentT = new Date().getTime() / 1000;
    setCurrentTime(parseInt(currentT));
  };

  useEffect(() => {
    d();
  }, []);

  // useEffect(() => {
  //   if (networkId !== nId) {
  //     toast("Wrong Network");
  //   }
  // }, [networkId]);

  // setInterval(() => {
  //   const currentT = new Date().getTime() / 1000;
  //   setCurrentTime(parseInt(currentT));
  // }, 10000);

  // useEffect(() => {
  //   const d = async () => {
  //     const p = await phase();

  //     setPhase(p);
  //   };
  //   d();
  // }, [currentTime]);

  // const renderer = ({ days, hours, minutes, seconds, completed }) => {
  //   if (completed) {
  //     // Render a completed state

  //     return 0;
  //   } else {
  //     // Render a countdown
  //     return (
  //       <span>
  //         {days}:{hours}:{minutes}:{seconds}
  //       </span>
  //     );
  //   }
  // };

  // console.log("bool", mintPhaseDuration);

  // const time = async () => {
  //   const pOneST = parseInt(mintPhaseDuration?.phaseOneSTime);
  //   const pTwoST = parseInt(mintPhaseDuration?.phaseTwoSTime);
  //   const pThreeST = parseInt(mintPhaseDuration?.phaseThreeSTime);
  //   if (currentTime < pOneST) {
  //     // console.log("phase1");
  //     return (
  //       <Countdown date={Date.now() + pOneST * 1000} renderer={renderer} />
  //     );
  //   } else if (currentTime < pTwoST) {
  //     // console.log("phase2");
  //     return (
  //       <Countdown date={Date.now() + pTwoST * 1000} renderer={renderer} />
  //     );
  //   } else if (currentTime < pThreeST) {
  //     // console.log("phase3");
  //     return (
  //       <Countdown date={Date.now() + pThreeST * 1000} renderer={renderer} />
  //     );
  //   }
  // };

  // const phase = async () => {
  //   const pOneST = parseInt(mintPhaseDuration?.phaseOneSTime);
  //   const pOneD = parseInt(mintPhaseDuration?.phaseOneSpan);

  //   const pTwoST = parseInt(mintPhaseDuration?.phaseTwoSTime);
  //   const pTwoD = parseInt(mintPhaseDuration?.phaseTwoSpan);

  //   const pThreeST = parseInt(mintPhaseDuration?.phaseThreeSTime);

  //   const pOneTSupply = parseInt(mintPhaseDuration?.phaseOneSupply);
  //   const pThreeTSupply = parseInt(mintPhaseDuration?.phaseThreeSupply);

  //   const phaseOneTMinted = parseInt(await phaseMint(1));
  //   const phaseTwoTMinted = parseInt(await phaseMint(2));
  //   const phaseThreeTMinted = parseInt(await phaseMint(3));

  //   if (currentTime > pOneST && currentTime < pOneST + pOneD) {
  //     setCurrentPhaseNumber(1);
  //     setMintDisable(false);
  //     return (
  //       <>
  //         <p className="font-semibold mt-4 text-xl">
  //           Round 1 -{phaseOneTMinted + "/" + pOneTSupply}
  //         </p>
  //         <p className="text-sm">2pm UTC for 24 hours</p>
  //       </>
  //     );
  //   } else if (currentTime > pTwoST && currentTime < pTwoST + pTwoD) {
  //     setCurrentPhaseNumber(2);
  //     setMintDisable(false);
  //     return (
  //       <>
  //         <p className="font-semibold mt-4 text-xl">
  //           Round 2 -
  //           {phaseTwoTMinted + "/" + (pThreeTSupply - (phaseOneTMinted + 6))}
  //         </p>
  //         <p className="text-sm">2pm UTC for 24 hours</p>
  //       </>
  //     );
  //   } else if (currentTime > pThreeST) {
  //     setCurrentPhaseNumber(3);
  //     setMintDisable(false);
  //     return (
  //       <>
  //         <p className="font-semibold mt-4 text-xl">
  //           Round 3 -
  //           {phaseThreeTMinted +
  //             "/" +
  //             (pThreeTSupply - phaseOneTMinted - phaseTwoTMinted)}
  //         </p>
  //         <p className="text-sm">2pm UTC for 24 hours</p>
  //       </>
  //     );
  //   } else {
  //     setMintDisable(true);
  //   }
  // };

  // console.log(
  //   parseInt(mintPhaseDuration?.phaseOneTMinted) >=
  //     parseInt(mintPhaseDuration?.phaseOneSupply)
  // );

  return (
    <>
      <div className="flex md:flex-row flex-col-reverse items-center justify-evenly mt-24 text-white text-center">
        <div className="">
          <img
            className="w-11/12 md:w-80 lg:w-96 m-auto mt-10 md:mt-0"
            src="images/plebeianCouncil.png"
          />
          <div>
            <p className=" w-11/12 sm:w-[27rem] m-auto mt-5">
              {mintPhaseDuration?.maxSupplyPlebToken || "4500"} Unique Roman
              themed NFTs hosted on the BSC Chain that enable passive income
              opportunities!
            </p>

            <p className="mt-3 font-bold text-xl">Round 3</p>
            <p className="text-sm">April 14th for 1 Week</p>
            <div className="flex w-11/12 sm:w-[27rem] justify-evenly mt-2">
              {/* <div>
                <img src="images/Liquidifty.png" className="w-24" />
                <p className="text-sm font-bold">Liquidifty</p>
                <a
                  href="https://app.liquidifty.io/@vEmpireDDAO"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className="button mt-1 px-4 text-sm">Mint</button>
                </a>
              </div> */}
              <div>
                <img src="images/Formless.png" className="w-20 mt-5" />
                <p className="text-sm font-bold mt-1">Formless</p>
                <a
                  href="https://www.formless.me/nftDetail?id=22957"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className="button mt-3 px-4 text-sm">Mint</button>
                </a>
              </div>
            </div>

            {/* {currentPhase} */}
            {/* <div className="mt-4">
              <button
                className="circle w-8 h-8 "
                onClick={() => {
                  if (mintValue) {
                    setMintValue(parseFloat(mintValue) - 1);
                  }
                }}
              >
                -
              </button>
              <input
                value={mintValue}
                onChange={(e) => setMintValue(e.target.value)}
                className="rounded-full w-24 h-8 mx-3 text-center text-black"
                placeholder="0"
              />
              <button
                className="circle w-8 h-8"
                onClick={() => setMintValue(parseFloat(mintValue) + 1)}
              >
                +
              </button>
            </div>
            <button
              className="button mt-3"
              disabled={
                parseInt(mintPhaseDuration?.totalMinted) +
                  parseInt(mintValue) >=
                  parseInt(mintPhaseDuration?.phaseThreeSupply) ||
                disable ||
                mintDisable
              }
              onClick={async () => {
                const whiteListed = await isWhitelisted(currentPhaseNumber);
                const account = await getAccount();
                if (!account.length) {
                  toast("Please connect your wallet");
                } else if (networkId !== nId) {
                  toast("Wrong network");
                } else if (whiteListed) {
                  if (
                    parseInt(mintValue) + totalNFTbuyed > nftLIMIT &&
                    currentPhaseNumber === 1
                  ) {
                    toast(`Max Mint is ${nftLIMIT} Per Wallet`);
                  } else {
                    if (parseInt(mintValue)) {
                      setDisable(true);
                      const minted = await Buy(mintValue, currentPhaseNumber);
                      if (minted) {
                        toast("Transaction successful");
                      } else {
                        toast("Transaction rejected");
                      }
                      setDisable(false);
                      d();
                    } else {
                      toast("You must select 1 or more.");
                    }
                  }
                } else {
                  toast("Address Not Whitelisted");
                }
              }}
            >
              Mint
            </button>
            <p className="mt-3">Total Purchased - {totalNFTbuyed}</p>
            <button
              className="button mt-3"
              disabled={disable || !parseInt(totalClaimableNFT)}
              onClick={async () => {
                const account = await getAccount();
                if (!account.length) {
                  toast("Please connect your wallet");
                } else if (networkId !== nId) {
                  toast("Wrong network");
                } else {
                  setDisable(true);
                  await Redeem();
                  setDisable(false);
                  d();
                }
              }}
            >
              Redeem
            </button>
            <p className="mt-3">Claimable - {totalClaimableNFT}</p> */}
          </div>
          {/* <p className="font-semibold mt-4 text-xl">
            Total Minted -{" "}
            {mintPhaseDuration?.totalMinted !== undefined &&
            mintPhaseDuration?.maxSupplyPlebToken !== undefined
              ? mintPhaseDuration?.totalMinted +
                "/" +
                mintPhaseDuration?.maxSupplyPlebToken
              : "0/0"}
          </p> */}
        </div>
        <img className="w-10/12 md:w-80 lg:w-96" src="images/plebeianMan.png" />
      </div>
      <div className="text-white flex flex-col sm:flex-row w-full items-center justify-evenly mt-20">
        <div className="text-center">
          <p className="font-semibold mt-4 text-xl">Round 1</p>
          <p className=" text-base pb-2">March 24th</p>
          <p className="text-sm">2pm UTC for 24 hours</p>
          <p className="text-sm">(or 1000 Sold out)</p>
        </div>
        <div className="text-center">
          <p className="font-semibold mt-4 text-xl">Round 2</p>
          <p className=" text-base pb-2">April 7th</p>
          <p className="text-sm">2pm UTC for 24 hours</p>
        </div>
        <div className="text-center">
          <p className="font-semibold mt-4 text-xl">Round 3</p>
          <p className=" text-base pb-2">April 14th</p>
          <p className="text-sm">2pm UTC for 1 Week</p>
        </div>
      </div>
    </>
  );
}

export default CouncilPartA;
