import React, { useMemo, useState } from "react";
import FooterLeft from "./FooterLeft";
import FooterRight from "./FooterRight";
import debounce from "lodash.debounce";

function Footer() {
  const [windowSize, setWindowSize] = useState(320);

  function displayWindowSize(this: any) {
    // your size calculation code here

    setWindowSize(this.innerWidth);
    // document.getElementById("dimensions").innerHTML = myWidth + "x" + myHeight;
  }
  const debouncedWindowHandler = useMemo(() => {
    return debounce(displayWindowSize, 300);
  }, []);
  window.onresize = debouncedWindowHandler;
  window.onload = debouncedWindowHandler;
  return (
    <div
      style={{ backgroundColor: "#150135" }}
      className="flex md:flex-row flex-col items-center justify-evenly mt-12 text-white  h-[35rem] md:h-80"
    >
      <FooterLeft windowSize={windowSize} />
      <FooterRight windowSize={windowSize} />
    </div>
  );
}

export default Footer;
