import React from "react";
import CouncilPartA from "./CouncilPartA";
import CouncilPartB from "./CouncilPartB";

function Council() {
  return (
    <div>
      <CouncilPartA />
      <CouncilPartB />
    </div>
  );
}

export default Council;
