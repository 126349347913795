import React from "react";

function VempireGame() {
  return (
    <div className="flex md:flex-row flex-col items-center justify-evenly md:mt-10 mt-24  text-white text-left">
      <div className="text-center md:text-left">
        <p className="text-3xl sm:text-5xl font-semibold">vEmpire DDAO</p>
        <p className="w-80 mt-6 mb-12 md:mb-3">
          vEmpire DDAO is the world’s largest Decentralized Metaverse Investment
          Organization, featuring industry leading staking pools, play-to-earn
          games, Metaverse events organization and a P2E and GameFi focused
          incubator.
        </p>
        <a
          href="https://v-empire.io/games/"
          target="_blank"
          className="button mt-6   py-2"
          rel="noreferrer"
        >
          Find Out More
        </a>
      </div>
      <img
        className="w-11/12 md:w-[40rem] mt-20 md:mt-0"
        src="images/games.png"
        alt="image not found"
      />
    </div>
  );
}

export default VempireGame;
