import { phase } from "./phaseAddress/phase.js";
import { phaseTwoAddress } from "./phaseAddress/phaseTwoAddresses.js";

// Binance mainnet
export const nftAddress = "0xf405d3e755ad4e3afe653edf1fc6f3f493ea3469";
export const plebNftAddress = "0xbC96f18700055C044Feb2902b5e39CDBa81CBf86";

// Binance testnet
// export const nftAddress = "0x6C8D13cc838a8980402eBd35c59EeE053dcdC663";

// Whitelisting Addresses

// export const phaseOneAddresses = [
//   "0x51Ac46dB265b6A9c478B7310Fcdf2dBb687cA6fC",
//   "0x81eE3109aAfd9bbf6263eF9e6E9e4D789FF88f22",
// ];

// export const phaseTwoAddresses = [
//   "0x08Ae49a46230De5fa2fcB7c46B04C02De36c7647",
//   "0x4D070DbA99118896dC65eDD3da383b6037392737",
// ];

export const phaseOneAddresses = phase;

export const phaseTwoAddresses = phaseTwoAddress;
