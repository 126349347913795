import React, { useEffect } from "react";
import "./App.css";

import Header from "./Components/Header";
import Council from "./Components/Council/Council";
import Trait from "./Components/Traits/Traits";
import Utility from "./Components/Utility";
import Community from "./Components/Community";
import Footer from "./Components/Footer/Footer";
import VempireGame from "./Components/VempireGame";
import detectEthereumProvider from "@metamask/detect-provider";
import { useSelector, useDispatch } from "react-redux";
import {
  setWeb3,
  setMetamask,
  setNetworkId,
} from "./features/counter/nftSlice";
import { chainChanged, hexToNumber } from "./Common/nftFunctions";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
var Web3 = require("web3");
const web3 = new Web3(Web3.givenProvider || "ws://localhost:8545");

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    if (window && window.ethereum) {
      window.ethereum.on("accountsChanged", (a) => window.location.reload());
      window.ethereum.on("chainChanged", (chainId) => {
        window.location.reload();
      });
    }
  }, []);

  useEffect(() => {
    const detectMetamask = async () => {
      // this returns the provider, or null if it wasn't detected
      const provider = await detectEthereumProvider();
      if (provider) {
        // If the provider returned by detectEthereumProvider is not the same as
        // window.ethereum, something is overwriting it, perhaps another wallet.
        if (provider !== window.ethereum) {
          dispatch(setMetamask(false));
          return "";
        }
        // Access the decentralized web!
        window.web3 = new Web3(window.ethereum);
        const chainId = await web3?.eth?.getChainId();
        dispatch(setNetworkId(hexToNumber(chainId)));
        dispatch(setMetamask(true));
      } else {
        dispatch(setMetamask(false));
      }
    };
    detectMetamask();
  }, []);

  // useEffect(() => {
  //   if (window && window.ethereum) {
  //     window.ethereum.on("accountsChanged", (a) => window.location.reload());
  //     window.ethereum.on("chainChanged", (chainId) => {
  //       dispatch(setNetworkId(hexToNumber(chainId)));
  //       window.location.reload();
  //     });
  //   }
  // }, []);

  return (
    <div className="App">
      <Header />
      <Council />
      <Trait />
      <Utility />
      <VempireGame />
      <Community />
      <Footer />
      <ToastContainer autoClose={2000} hideProgressBar={true} />
      <a href="https://raritysniper.com/nft-drops-calendar"></a>
    </div>
  );
}

export default App;
