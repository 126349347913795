import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Provider } from "react-redux";
import { fetchCount } from "./counterAPI";

const initialState = {
  isMetamask: true,
  web3: Provider,
  networkId: 56,
};

// export const incrementAsync = createAsyncThunk(
//   "counter/fetchCount",
//   async (amount) => {
//     const response = await fetchCount(amount);
//     // The proposalSlice we return becomes the `fulfilled` action payload
//     return response.data;
//   }
// );

export const nftSlice = createSlice({
  name: "basicConfig",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setMetamask: (state, action) => {
      state.isMetamask = action.payload;
    },
    setNetworkId: (state, action) => {
      state.networkId = action.payload;
    },
    setWeb3: (state, action) => {
      state.web3 = action.payload;
    },
  },
});

export const { setMetamask, setNetworkId, setWeb3 } = nftSlice.actions;

// The function below is called a selector and allows us to select a proposalSlice from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.proposalSlice)`
export const selectNFT = (state) => state.basicConfig;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd = (amount) => (dispatch, getState) => {
//   const currentproposalSlice = selectCount(getState());
//   if (currentproposalSlice % 2 === 1) {
//     dispatch(incrementByAmount(amount));
//   }
// };

export default nftSlice.reducer;
