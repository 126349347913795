import React, { useMemo, useState } from "react";

interface FooterLeftProps {
  windowSize: number;
}

const FooterLeft: React.FC<FooterLeftProps> = ({ windowSize }) => {
  return (
    <div className="flex flex-col justify-evenly h-3/5 md:h-full items-start   w-10/12 md:w-4/12  md:px-0">
      <img
        className="w-40 mt-3"
        src="images/vEmpireLogo.png"
        alt="image not found"
      />
      <p className="text-3xl md:text-4xl font-semibold">Your Gateway</p>
      <p className="text-3xl md:text-4xl font-semibold">To The Metaverse</p>
      {windowSize > 769 ? (
        <>
          <div className="flex w-52 justify-between">
            <a
              href="https://twitter.com/vEmpireDDAO"
              target="_blank"
              rel="noreferrer"
            >
              <img className="w-7" src="/images/twitter.png" alt="Not Found" />
            </a>
            <a
              href="https://discord.com/invite/Wk3aF3PNKM"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="w-7"
                src="/images/blackDiscord.png"
                alt="Not Found"
              />
            </a>
            <a
              href="https://t.me/vEmpireannouncements"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="w-7"
                src="/images/telegramm.png"
                alt="Not Found"
              />
            </a>

            <a
              href="https://www.youtube.com/c/vEmpireDDAO"
              target="_blank"
              rel="noreferrer"
            >
              <img className="w-7" src="/images/youtube.png" alt="Not Found" />
            </a>

            <a
              href="https://v-empire-digital.medium.com/"
              target="_blank"
              rel="noreferrer"
            >
              <img className="w-7" src="/images/medium.png" alt="Not Found" />
            </a>
          </div>
          <p className=" ">Copyright &copy; 2022 vEmpire</p>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default FooterLeft;
