import React from "react";

function CouncilPartB() {
  return (
    <div id="about" className="h-full md:h-screen pt-16">
      <div className="flex md:flex-row flex-col items-center justify-evenly  sm:mt-20 text-white text-left w-11/12 sm:w-full m-auto">
        <img
          className="w-10/12 md:w-80 lg:w-96 mt-10 sm:mt-0"
          src="images/VEmpireWarrior.png"
          alt="image not found"
        />
        <div className="flex flex-col items-start w-10/12 md:w-6/12 md:mt-0 mt-10">
          <p className="text-3xl sm:text-5xl font-semibold self-start w-10/12">
            Who are
          </p>
          <p className="text-3xl sm:text-5xl font-semibold">
            The Plebeian Council?
          </p>
          <p className=" mt-6">
            The Plebeian Council is the principal assembly of the common people
            of vEmpire’s Metaverse adaption of ancient Rome.
          </p>
          <p className=" mt-6">
            They will act as their holder’s identities and provide unique
            benefits for their holders within vEmpire’s Roman ecosystem!
          </p>
        </div>
      </div>
    </div>
  );
}

export default CouncilPartB;
