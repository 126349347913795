import React from "react";

function Community() {
  return (
    <div className="flex md:flex-row flex-col-reverse items-center justify-evenly mt-20 md:mt-28 mb-16 text-white text-left">
      <img
        className="w-32 h:32 md:w-48 md:h-48 mt-10 md:mt-0"
        src="images/discord.png"
        alt="image not found"
      />
      <div className="w-11/12 md:w-5/12 text-center md:text-left">
        <p className="text-3xl font-semibold">Join vEmpire’s</p>
        <p className="text-3xl font-semibold">Pleb Community</p>
        <p className="w-full mt-3">
          Make sure that you join our Discord server to stay up to date with the
          latest news and announcements surrounding the largest GameFi, NFT &
          Metaverse investment organization.
        </p>
        <p className="w-full mt-3 mb-6 ">
          You can also verify yourself as an official NFT holder to gain access
          to exclusive channels and features!
        </p>
        <a
          href="https://discord.com/invite/Wk3aF3PNKM"
          target="_blank"
          className="button mt-6 py-2"
          rel="noreferrer"
        >
          Join Discord
        </a>
      </div>
    </div>
  );
}

export default Community;
