import React from "react";

function Traits() {
  return (
    <div id="trait" className="h-full md:h-screen pt-16">
      <div className=" flex flex-col items-center justify-evenly text-white text-center">
        <p className="text-3xl sm:text-5xl font-semibold ">Traits</p>
        <p className="w-10/12 md:w-[27rem] mt-3">
          The Plebeian Council is a generative artwork project with a total of
          3.7 million unique trait possabilities.
        </p>
        <p className="w-10/12 md:w-[27rem] mt-3">
          Only 4500 will EVER be minted!
        </p>
        <div className="flex md:flex-row flex-col w-12/12 justify-around items-center mt-12 h-full">
          <img className="md:h-full sm:w-[50rem]" src="images/Traits.png" />
        </div>
      </div>
    </div>
  );
}

export default Traits;
